import dynamic from "next/dynamic";

import { MenuItem } from "@mui/material";

import emailjs from "@emailjs/browser";

import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import styles from "./contact.module.scss";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const FormControl = dynamic(() => import("@mui/material/FormControl"));
const InputLabel = dynamic(() => import("@mui/material/InputLabel"));
const Button = dynamic(() => import("@mui/material/Button"));
const SendIcon = dynamic(() => import("@mui/icons-material/Send"));
const FormGroup = dynamic(() => import("@mui/material/FormGroup"));
const FormControlLabel = dynamic(
  () => import("@mui/material/FormControlLabel")
);
const Checkbox = dynamic(() => import("@mui/material/Checkbox"));
const Snackbar = dynamic(() => import("../Snackbar"));

import Link from "next/link";

import { usePathname } from "next/navigation";
import { Routes } from "../../helperFunctions/routes";

function Contact() {
  emailjs.init(process.env.NEXT_PUBLIC_APP_EMAIL!);

  const [name, setName] = useState("");
  const [reinigungsArt, setReinigungsArt] = useState("");
  const [reinigungsIntervall, setReinigungsIntervall] = useState("");
  const [objektGroesse, setObjektGroesse] = useState(0);
  const [userMail, setUserMail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [isDatenschutzChecked, setIsDatenschutzChecked] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);

  const [isDatenschutzSnackbarOpen, setIsDatenschutzSnackbarOpen] =
    React.useState(false);
  const [isEmailSuccessSnackbarOpen, setIsEmailSuccessSnackbarOpen] =
    React.useState(false);
  const [isEmailErrorSnackbarOpen, setIsEmailErrorSnackbarOpen] =
    React.useState(false);

  const handleReinigungsArtChanged = (event: SelectChangeEvent) => {
    setReinigungsArt(event.target.value as string);
  };

  const handleReinigungsIntervallChanged = (event: SelectChangeEvent) => {
    setReinigungsIntervall(event.target.value as string);
  };

  const sendEmail = (e: any) => {
    e.preventDefault();

    if (isEmailSending) {
      return;
    }

    setIsEmailErrorSnackbarOpen(false);
    setIsEmailSuccessSnackbarOpen(false);

    if (!isDatenschutzChecked) {
      setIsDatenschutzSnackbarOpen(true);
    } else {
      setIsEmailSending(true);

      if (process.env.NEXT_PUBLIC_APP_EMAIL) {
        emailjs
          .sendForm(
            "service_musliu_reinigung",
            "template_8gbbvdk",
            e.target,
            process.env.NEXT_PUBLIC_APP_EMAIL
          )
          .then(
            (_result) => {
              setIsEmailSending(false);
              setIsEmailSuccessSnackbarOpen(true);
            },
            (_error) => {
              setIsEmailSending(false);
              setIsEmailErrorSnackbarOpen(true);
            }
          );
      } else {
        // wenn im deployten Zustand der Key fehlt, wird so das senden abgebrochen
        setIsEmailSending(false);
        setIsEmailErrorSnackbarOpen(true);
      }
    }
  };

  const RenderHeadline = () => {
    const pathname = usePathname();
    return pathname === Routes.Kontakt ? (
      <h1 className={styles["contact-title"]}>Kontakt</h1>
    ) : (
      <p className={styles["contact-title"]}>Kontakt</p>
    );
  };

  return (
    <div
      className={`component-layout component-layout-border ${styles["contact-container"]}`}
    >
      <div className={styles["contact-header-container"]}>
        {RenderHeadline()}
        <p className={styles["contact-subtitle"]}>
          Bitte geben Sie in dem folgenden Formular einige Informationen an,
          damit wir Ihnen umgehend ein kostenloses Angebot erstellen können. Wir
          kommen auch gerne unverbindlich zu einem Vorstellungstermin in Ihre
          Geschäftsräume.
        </p>
      </div>
      <form className={styles["form-container"]} onSubmit={sendEmail}>
        <div className={styles["form-controls"]}>
          <FormControl variant="standard">
            <InputLabel htmlFor="username"></InputLabel>
            <TextField
              id="username"
              required
              name="username"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="userMail"></InputLabel>
            <TextField
              id="userMail"
              required
              name="userMail"
              label="Email"
              value={userMail}
              type={"email"}
              onChange={(e) => setUserMail(e.target.value)}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="userPhone"></InputLabel>
            <TextField
              id="userPhone"
              name="userPhone"
              label="Telefon"
              value={userPhone}
              onChange={(e) => setUserPhone(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <InputLabel id="reinigungsArt">Reinigungsart</InputLabel>
            <Select
              labelId="reinigungsArt-label"
              id="reinigungsArt"
              name="reinigungsArt"
              value={reinigungsArt}
              label="Reinigungsart"
              onChange={handleReinigungsArtChanged}
            >
              <MenuItem value={"Grundreinigung"}>Grundreinigung</MenuItem>
              <MenuItem value={"Bueroreinigung"}>Büroreinigung</MenuItem>
              <MenuItem value={"Praxisreinigung"}>Praxisreinigung</MenuItem>
              <MenuItem value={"Kindergartenreinigung"}>
                Kindergartenreinigung
              </MenuItem>
              <MenuItem value={"Treppenhausreinigung"}>
                Treppenhausreinigung
              </MenuItem>
              <MenuItem value={"Fensterreinigung"}>Fensterreinigung</MenuItem>
              <MenuItem value={"Solaranlagenreinigung"}>
                Solaranlagenreinigung
              </MenuItem>
              <MenuItem value={"Baureinigung"}>Baureinigung</MenuItem>
              <MenuItem value={"Gastronomiereinigung"}>
                Gastronomiereinigung
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="reinigungsIntervall">
              Reinigungsintervall
            </InputLabel>
            <Select
              labelId="reinigungsIntervall-label"
              id="reinigungsIntervall"
              name="reinigungsIntervall"
              value={reinigungsIntervall}
              label="Reinigungsintervall"
              onChange={handleReinigungsIntervallChanged}
            >
              <MenuItem value={"1x pro Woche"}>1x pro Woche</MenuItem>
              <MenuItem value={"2x pro Woche"}>2x pro Woche</MenuItem>
              <MenuItem value={"3x pro Woche"}>3x pro Woche</MenuItem>
              <MenuItem value={"4x pro Woche"}>4x pro Woche</MenuItem>
              <MenuItem value={"5x pro Woche"}>5x pro Woche</MenuItem>
              <MenuItem value={"6x pro Woche"}>6x pro Woche</MenuItem>
              <MenuItem value={"Jede Woche"}>Jede Woche</MenuItem>
              <MenuItem value={"Jede zweite Woche"}>Jede zweite Woche</MenuItem>
              <MenuItem value={"Einmalig"}>Einmalig</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="objektGroesse"></InputLabel>
            <TextField
              id="objektGroesse"
              name="objektGroesse"
              label="Objekt in m²"
              value={objektGroesse}
              type={"number"}
              onChange={(e) => setObjektGroesse(parseInt(e.target.value))}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="userMessage"></InputLabel>
            <TextField
              id="userMessage"
              required
              name="userMessage"
              label="Nachricht"
              multiline
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            />
          </FormControl>
        </div>
        <FormGroup>
          <FormControlLabel
            className={styles["datenschutz-checkbox-container"]}
            control={
              <Checkbox
                value={isDatenschutzChecked}
                onChange={(_event: React.ChangeEvent, checked: boolean) => {
                  setIsDatenschutzChecked(checked);
                }}
              />
            }
            label={
              <label>
                Ich stimme den Bedingungen in der{" "}
                <Link
                  href="/datenschutz"
                  title="Hier kommen Sie zu unserer Datenschutzerklärung"
                  passHref
                  className={styles["datenschutz-link"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutzerklärung
                </Link>{" "}
                zu.
              </label>
            }
          />
        </FormGroup>
        <Button
          className={styles["submit-button"]}
          type="submit"
          variant="contained"
          endIcon={<SendIcon />}
          disabled={isEmailSending}
        >
          {isEmailSending ? "wird gesendet..." : "senden"}
        </Button>
      </form>
      <Snackbar
        snackbarKey="Datenschutz"
        isOpen={isDatenschutzSnackbarOpen}
        text={
          "Sie müssen den Bedingungen in der Datenschutzerklärung zustimmen."
        }
        onClose={() => {
          setIsDatenschutzSnackbarOpen(false);
        }}
      />
      <Snackbar
        snackbarKey="EmailSuccess"
        isOpen={isEmailSuccessSnackbarOpen}
        text={"Ihre Nachricht wurde erfolgreich versendet."}
        variant={"success"}
        onClose={() => {
          setIsEmailSuccessSnackbarOpen(false);
        }}
      />
      <Snackbar
        snackbarKey="EmailError"
        isOpen={isEmailErrorSnackbarOpen}
        text={"Es ist ein Fehler aufgetreten, rufen Sie uns gerne an."}
        variant={"error"}
        onClose={() => {
          setIsEmailErrorSnackbarOpen(false);
        }}
      />
    </div>
  );
}

export default Contact;
